import React, { createContext, useContext, useEffect, useState } from "react";
import queryString from "query-string";

const defaultPrePopState = {
  firstname: "",
  lastname: "",
  address: "",
  zip: "",
  city: "",
  state: "",
  email: "",
  phone: "",
};

const PrePopContext = createContext(defaultPrePopState);

export const usePrePopContext = () => {
  return useContext(PrePopContext);
}

export const PrePopProvider = ({ children }) =>  {
  const queryParams = queryString.parse(window.location.search);
  const [prePopState, setPrePopState] = useState(defaultPrePopState);

  useEffect(() => {
    setPrePopState({
      firstname: queryParams.name || "",
      lastname: queryParams.lastName || "",
      email: queryParams.email || "",
      phone: queryParams.phoneNumber || "",
      zip: queryParams.zipCode || "",
      address: queryParams.streetAddress || "",
    });
  }, [
    queryParams.name,
    queryParams.lastName,
    queryParams.email,
    queryParams.phoneNumber,
    queryParams.zipCode,
    queryParams.streetAddress
  ]);

  return (
    <PrePopContext.Provider value={prePopState}>
      {children}
    </PrePopContext.Provider>
  );
}
