import { createContext, useContext, useEffect, useState } from 'react';

const defaultState = {
  propertycondition: 'needs work',
  reasonforselling: 'need to access cash',
  timeframe: 'asap',
  propertyvalue: 550000,
  city: '',
  state: '',
  zip: '',
  realtorlisting: 'no',
  step: 1,
  propertytype: 'single family'
};

const FormDataContext = createContext();

export const useFormDataContext = () => useContext(FormDataContext);

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState(defaultState);

  const updateFormData = (data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...data,
    }));
  };

  const clearStorage = () => setFormData(defaultState);

  const updateStep = (step) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      step,
    }));
  };

  return (
    <FormDataContext.Provider
      value={{ formData, updateFormData, updateStep, clearStorage }}
    >
      {children}
    </FormDataContext.Provider>
  );
};
