import { FormProvider, useForm } from 'react-hook-form';
import {
  FormStep1,
  FormStep10,
  FormStep11,
  FormStep3,
  FormStep4,
  FormStep5,
  FormStep6,
} from './steps';
import { getErrorMessage, submitForm } from '../../lib/utils';
import { useAppContext, useFormDataContext, usePrePopContext, useLendersContext } from '../../lib/contexts';
import { CustomLoader } from '../CustomComponents';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import {useEffect, useState} from 'react';

function Form() {
  const { appContext } = useAppContext();
  const { formData, clearStorage, updateFormData } = useFormDataContext({});
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  const { step } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const { formState } = methods;
  const { errors } = formState;
  const className = `stepBar step${step}`;
  const navigate = useNavigate();
  const { setLendersContext } = useLendersContext();

  const handleGlobalSubmit = async (data) => {
    const combinedData = { ...data, ...formData, ...appContext };

    setIsLoading(true);

    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    Object.keys(data).forEach((key) => {
      if (formData[key] === '' && data[key] !== '') {
        combinedData[key] = data[key];
      }
    });

    const submitResult = await submitForm(combinedData);

    let pixel = null;

    if (submitResult.pixel) {
      pixel = submitResult.pixel;
    }

    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
    };

    if (submitResult.lenders && submitResult.lenders.length) {
      lendersContextData.lenders = submitResult.lenders;
    }

    setLendersContext(lendersContextData);

    setIsLoading(false);

    if (submitResult.status == 'matched' && submitResult.lenders) {
      await navigate('/thank-you-lender-list',{ state: {pixel:pixel} });
    } else {
      await navigate('/thank-you',{ state: {pixel:pixel} });
    }

    clearStorage();
  };

  useEffect(() => {
    if (appContext.prepop) {
      updateFormData({
        firstname: appContext.prepop.firstname || formData.firstname,
        lastname: appContext.prepop.lastname || formData.lastname,
        email: appContext.prepop.email || formData.email,
        phone: appContext.prepop.phone || formData.phone,
        zip: formData.zip || appContext.prepop.zip,
        address: appContext.prepop.address || formData.address,
      });
    }
  }, [appContext]);

  return (
    <>
      <CustomLoader isLoading={isLoading} />
      <div className="step-form-wrap">
        <h3 className="text-center">
          Embark on a Bright Financial Future Today.
        </h3>
        <div className={className}>
          <div className="steps col-12">
            <span>Step &nbsp;</span>
            <span className="current">{step} </span> &nbsp;/ 11
          </div>
        </div>
        <FormProvider {...methods}>
          <form
            id="leadform"
            className="step-form"
            name="leadform"
            onSubmit={methods.handleSubmit(handleGlobalSubmit)}
          >
            <div className="step active" data-step={step}></div>
            <div className="inner">
              {!isEmpty(errors) && (
                <div className="errors">{getErrorMessage(errors)}</div>
              )}
              {step === 1 && <FormStep5 />}
              {step === 2 && <FormStep1 />}
              {step === 3 && <FormStep3 />}
              {step === 4 && <FormStep4 />}
              {step === 5 && <FormStep6 />}
              {step === 6 && <FormStep10 />}
              {step === 7 && <FormStep11 />}
            </div>
          </form>
        </FormProvider>{' '}
      </div>
    </>
  );
}

export default Form;
