import React, { useEffect, useRef, useState } from "react";
import {
  useAddressContext,
  useAppContext,
  useFormDataContext,
  usePrePopContext,
} from "../../../lib/contexts";
import googlereviews from "../../../assets/img/google-reviews.png";
import mcafee from "../../../assets/img/mcafee.png";
import truste from "../../../assets/img/truste.png";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { isZipCodeValid } from "../../../lib/utils";

function HeroArea() {
  const queryParams = queryString.parse(window.location.search);
  const { appContext } = useAppContext();
  const { AffiliateID, clickID, S1, lead_id } = appContext;
  const { updateFormData, formData } = useFormDataContext();
  const prePop = usePrePopContext();
  const { setAddressData } = useAddressContext();
  const [address, setAddress] = useState(formData.zip || prePop.zip);
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: ["ca", "us"] },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };
  
  const navigate = useNavigate();

  /*useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        setAddressError(true); // Invalid place
        return;
      }
      // Extract city, state, and zip code from address components
      const street =
        place.address_components.find((component) =>
          component.types.includes('street_number')
        )?.long_name || '';
      const route =
        place.address_components.find((component) =>
          component.types.includes('route')
        )?.long_name || '';
      const city =
        place.address_components.find((component) =>
          component.types.includes('locality')
        )?.long_name || '';
      const state =
        place.address_components.find((component) =>
          component.types.includes('administrative_area_level_1')
        )?.short_name || '';
      const zipCode =
        place.address_components.find((component) =>
          component.types.includes('postal_code')
        )?.long_name || '';
      const country =
        place.address_components.find((component) =>
          component.types.includes('country')
        )?.long_name || '';
      setAddress2(`${street} ${route}`);
      // Update state with extracted values
      setAddressError(false);
      setAddress(place.formatted_address);
      // You can also update the hidden input fields if needed
      setLocality(city);
      setState(state);
      setZipCode(zipCode);
      setCountry(country);
    });

    // Clean up listener on component unmount
    return () => {
      window.google.maps.event.clearListeners(autocomplete, 'place_changed');
    };
  }, []);
  useEffect(() => {
    setAddressData({
      address2,
      locality,
      state,
      zipCode,
      country,
    });
    localStorage.setItem(
      'addressData',
      JSON.stringify({
        address2,
        locality,
        state,
        zipCode,
        country,
      })
    );
  }, [address2, locality, state, zipCode, country, setAddressData]);*/

  useEffect(() => {
    setAddress(formData.zip || prePop.zip || "");
  }, [formData.zip, prePop.zip]);
  
  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 5) {
      setAddress(value);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const isValidZipCode = await isZipCodeValid(address);
    const {city, state} = isValidZipCode;

    if (address.length > 0 && address.length < 5) {
      setAddressError(true);
      setErrorMessage("ZIP code must have 5 digits");
      return;
    } else if (address.trim() === "" || isValidZipCode.error != '') {
      setAddressError(true);
      setErrorMessage("Please enter a valid ZIP code");
      return;
    } else {
      setAddressError(false);
      setErrorMessage("");

      updateFormData({
        step: 1,
        zip: address,
        city: city,
        state: state,
      });

      navigate("/form");
    }
  };

  return (
    <>
      <section className="heroArea pt-5 pb-0" id="main">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="heroContent mt-5 pt-5">
                <h1 className="text-white font-xbold pt-5 mt-5">
                  Need Cash Fast? Get Top-Dollar on Your Home TODAY!
                </h1>
                <h2 className="text-white font-xbold my-5">
                  No Realtors, No Repairs, No Stress!
                </h2>
                <form
                  id="form"
                  className={addressError ? "zip-form-error" : null}
                >
                  <input
                    id="address"
                    name="address"
                    ref={inputRef}
                    onChange={handleChange}
                    value={address}
                    placeholder="Please Enter Zip Code *"
                    required=""
                    className={addressError ? "error" : ""}
                  />
                  <input
                    id="address2"
                    name="address2"
                    type="hidden"
                    className="hidden"
                    value={address2}
                  />
                  <input
                    id="city"
                    name="city"
                    type="hidden"
                    className="hidden"
                    value={locality}
                  />
                  <input
                    id="state"
                    name="state"
                    type="hidden"
                    className="hidden"
                    value={state}
                  />
                  <input
                    id="postcode"
                    name="postcode"
                    type="hidden"
                    className="hidden"
                    value={zipCode}
                  />
                  <input
                    id="country"
                    name="country"
                    type="hidden"
                    className="hidden"
                    value={country}
                  />
                  <input
                    id="AffiliateID"
                    name="AffiliateID"
                    type="hidden"
                    className="hidden"
                    value={AffiliateID}
                  />
                  <input
                    id="lead_id"
                    name="lead_id"
                    type="hidden"
                    className="hidden"
                    value={lead_id}
                  />
                  <input
                    id="ip_address"
                    name="ip_address"
                    type="hidden"
                    className="hidden"
                    value={formData.ip_address}
                  />
                  <input
                    id="S1"
                    name="S1"
                    type="hidden"
                    className="hidden"
                    value={S1}
                  />
                  <input
                    id="lp_request_id"
                    name="lp_request_id"
                    type="hidden"
                    className="hidden"
                    value={clickID}
                  />
                  <input
                    type="submit"
                    onClick={handleClick}
                    value="Get My Instant Cash Offer"
                    className="ctaGreen"
                  />
                </form>
                {addressError && (
                  <div className="text-danger font-xbold mt-3 zip-code-error">
                    {errorMessage}
                  </div>
                )}
              </div>
              <div className="logoArea mt-4 pt-4">
                <h6 className="text-white mt-5">As seen on</h6>
                <ul className="list-unstyled d-flex justify-content-between">
                  <li>
                    <img src={googlereviews} alt="google review" />
                  </li>
                  <li>
                    <img src={mcafee} alt="mcafee" />
                  </li>
                  <li>
                    <img src={truste} alt="truste" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroArea;
